
import './App.css';
import Navbar from './components/Navbar/Navbar'

import  Gallery from './pages/scroll/Gallery'
function App() {
  
  return (

    <div className="App">
      <Navbar></Navbar>


    </div>
  );
}

export default App;
